<template>
  <div>
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection
        titulo="MarketPlace"
        descripcion="Te brindamos un consejo inteligente y amigable, con nuestros experimentados abogados en materia electoral. Con la garantía de que la calidad del servicio puede seguirse en tiempo real."
      />

      <FormatosMarket />
      <FormatosChatET/>
      <LibrosIndexMarket />
      <BlogMarket />
      
      
       <ReviewSection /> 
      <!-- <ContactSection /> -->
      <ServiceSection />
      <RegistroFirma/>
      
      
      <!-- <CtaSection /> -->
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro />
      <ModalMenuSection />
      
    </div>
  </div>
</template>

<script>
import FormatosMarket from "../../Services/FormatosMarket";
import LibrosIndexMarket from "../../Services/LibrosIndexMarket";
import BlogMarket from "../../Services/BlogMarket";
import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/Services";
import ServiceSection from "../../Services/ServiceSeven";
import FormatosChatET from '../../ChatET/FormatosChatET'
// import ReviewSection from '../../Reviews/ReviewOne'
// import ContactSection from '../../Contact/ContactOne'
// import CtaSection from '../../Cta/Cta'
import FooterSection from "../../Footer/FooterOne";
import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";

import RegistroFirma from './RegistroFirma';


export default {
  name: "Services",
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,

    FormatosMarket,
    FormatosChatET,
    LibrosIndexMarket,
    BlogMarket,
    
    ServiceSection,

    RegistroFirma,
    // ReviewSection,
    // ContactSection,
    // CtaSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro: () => import("../../Modal/ModalSearch/ModalRegistro"),
  },
};
</script>

<style>
</style>