<template>
    <div 
        class="modal fade"
        id="ModalBookOrder"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalPrime"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo"></h5>
          </div>
            <div class="container-fluid d-flex justify-content-center  mx-0">
                <div class="row mb-4">
                    <div class="col-sm-12 col-lg-4">
                        <div class="card mx-auto" > 
                            <img :src="infoLibros[idLibro].url" class="img card-img-top">
                        </div>
                    </div> 
                    <div class="col-sm-12 col-lg-4"> 
                        <div class="card-body pt-1 px-0">
                            <div class="d-flex flex-row justify-content-between my-2 px-3"> 
                                <h3>{{infoLibros[idLibro].Titulo}}</h3>
                            </div>

                            <hr class="mt-2 mx-3">
                            <div class="d-flex flex-row justify-content-between my-2 px-3 texto-descriptivo"> 
                                <p align="justify" style="font-size: .8rem; ">
                                    <b>ABSTRACT:</b>
                                    {{infoLibros[idLibro].abstract}}
                                 </p>
                            </div>
                            <hr class="mt-2-mx-3">
        
                            <span class="d-flex justify-content-center text-muted">Autor: <a href="">José Olvieros Ruiz</a>  </span> 
                        </div>
                    </div>

                    <div class="col-sm-12 col-lg-4">
                        <div class="card-body pt-1 px-0">
                            <!-- PRECIOS SECTIONS
                            <div class="d-flex flex-row justify-content-between my-2 px-3"> 
                                <h3 class="text-precio text-muted mt-1">PRECIO ELECTORALTECH</h3>
                                <h6 class="price-text mt-3">&dollar;{{ infoLibros[idLibro].price}} <sub>MXN</sub></h6>
                            </div> -->
                            <hr class="mt-2 mx-3">
                            <h6 class="ml-3 price-text mt-3">Información Adicional</h6>
                            <div class="px-3"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="fas fa-barcode icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b>SKU:</b> {{infoLibros[idLibro].sku}} </span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-2">
                                        <i class="fas fa-print icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b> Editorial:</b> <a href="#">{{infoLibros[idLibro].editorial}} </a>  </span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-2">
                                        <i class="fas fa-book icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b>Género:</b> {{infoLibros[idLibro].genero}}</span>
                                    </div>
                                </div>

                                 <div class="row">
                                    <div class="col-2">
                                        <i class="fas fa-globe-americas icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b>Idioma:</b> {{infoLibros[idLibro].idioma}}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-2">
                                        <i class="fab fa-leanpub icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b>Formato:</b> {{infoLibros[idLibro].formato}}</span>
                                    </div>
                                </div>
                                
                            </div>
                            <hr class="mt-2-mx-3">
                            <!--
                            <div class="mx-3 mt-3 mb-2">
                                <button type="button" class="btn btn-danger btn-block" @click="comprarLibro()"><small>COMPRAR</small></button>
                            </div>
                            <small class="d-flex justify-content-center text-muted">*Aviso Legal</small> -->
                            <div class="mx-3 mt-3 mb-2"> 
                                <span class="info-text " style="font-size:1rem">  <b>Puedes Adquirirlo En:</b> </span> <br>
                                <a class="blog-btn mt-3" style="color:#eb34cf; cursor: pointer" target="_blank" href="https://www.gandhi.com.mx/justicia-electoral-en-mexico-avances-y-retrocesos-a-20-a-os-de-la-reforma-electoral-de-1996" >Librerías Ghandi (Físico)</a> <br>
                                <a class="blog-btn mt-3" style="color:#eb34cf; cursor: pointer" target="_blank" href="https://derechoglobal.com.mx/producto/justicia-electoral-en-mexico-avances-y-retrocesos-a-20-anos-de-la-reforma-electoral-de-1996/">Derecho Global (Físico)</a>
                                <a class="blog-btn mt-3" style="color:#eb34cf; cursor: pointer" target="_blank" href="https://ebooksderechoglobal.publica.la/library/filter?category=Electoral">Derecho Global (Digital)</a>
                            </div>
                        </div>  
                    </div>

                    <div>
                        <span>.</span> 
                    </div>
                           
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import {auth , fr} from "../../../firebase";


export default {
    name: "ModalBookOrder",
    props: ["idLibro"],
    data (){
        return{

            infoLibros: [
                {Titulo: "JUSTICIA ELECTORAL EN MÉXICO. AVANCES Y RETROCESOS A 20 AÑOS DE LA REFORMA ELECTORAL DE 1996",
                abstract: "Este texto es un análisis de la materia electoral desde la visión de la impartición de justicia, que recopila lo relevante que aconteció en veinte años en el ámbito de la justicia electoral, para lo cual el autor presenta diversas herramientas teóricas y prácticas. Precisamente uno de los logros de esta obra consiste en mostrar dos dimensiones de la materia: una avocada a sentar bases teóricas; y la otra, encaminada al análisis del tipo de controversias a las que se enfrenta el Tribunal Electoral del Poder Judicial de la Federación, así como las soluciones que ha dado....", 
                sku: "9786078685158",
                editorial: "DERECHO GLOBAL, VLEX",
                genero: " Colección V LEX, Derecho",
                idioma: "Español",
                formato: "ePub, Físico",
                url:"assets/img/book1-img.jpg",
                price: 499},

                {Titulo: "El título del segundo libro se desplegará en este espacio",
                abstract: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum arcu nec ornare mollis. Aliquam erat volutpat. Quisque a tincidunt turpis. Integer a sem tempus ligula rutrum vestibulum vitae vestibulum diam. Ut ac ante maximus, rhoncus mauris ut, egestas nisi. Praesent ultricies nunc eget quam venenatis, sed cursus risus semper. Nulla sagittis nisi tellus, eu gravida lectus sollicitudin eu. Fusce dictum dui vitae mauris lacinia lacinia. Morbi vehicula libero non ex faucibus, eget condimentum dui fringilla. Cras arcu sapien, ultricies id mauris quis, ultricies tempus quam. Nam viverra urna ut tellus lobortis ultrices. Nam sed pharetra sem, ac posuere ante. Aliquam vitae metus sed ipsum mattis mollis", 
                sku: "90123456789",
                editorial: "nombre de la editorial 2",
                genero: "no",
                idioma: "Español",
                formato: "ePub",
                price: 549},
            ],

            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },

    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd;
        },
    },
    methods: {
        comprarLibro(){
            console.log("ComprarLibro");

        },

        async pagarMes(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 1,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalPrime").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        }
        
    },
    
}
</script>

<style scoped>
.modal-body {
  display: block !important;
}

.info-text{
    margin-top: -20px;
}

.icons-info{
    width: 50px !important;
    height: 50px !important;
}

.texto-descriptivo{
    max-height: 600px !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    height: 500px!important;
    width: 300px!important;
    max-width: 300px!important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

