<template>
  <div class="registroFirma">
    <div class="container">
      <div class="col-12 text-center">
        <h2 class="title-text" style="font-size: 28px!important;">SERVICIOS DE <b>INTERMEDIACIÓN JURÍDICA</b></h2>
        <br>
        <h4>
          ¡Únete a nuestro equipo de trabajo!
        </h4>
        <h6>
          ¡Si eres un abogado que cumple con nuestros requisitos, regístrate!
        </h6>
      </div>

      <div class="row" style="margin-top: 40px">
        <div class="col-lg-6 col-xs-12">
          <div class="form-group">
            <input
              type="text"
              name="nombre"
              id="nombre"
              v-model.trim="form.nombre"
              :class="{ error: $v.form.nombre.$error }"
              class="form-control"
              placeholder="Nombre"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              name="nombreEmpresa"
              v-model.trim="form.nombreEmpresa"
              :class="{ error: $v.form.nombreEmpresa.$error }"
              id="nombreEmpresa"
              class="form-control"
              placeholder="Nombre de la empresa"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              name="email"
              id="email"
              v-model.trim="form.email"
              :class="{ error: $v.form.email.$error }"
              class="form-control"
              placeholder="Correo electrónico"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              name="sitio"
              v-model.trim="form.sitio"
              :class="{ error: $v.form.sitio.$error }"
              id="sitio"
              class="form-control"
              placeholder="Sitio web"
            />
          </div>
          <div class="form-group">
            <label for="">Tamaño de la firma</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="firma4"
                id="firma4"
                v-model="form.tamFirma"
                :class="{ error: $v.form.tamFirma.$error }"
                value="1"
              />
              <label class="form-check-label" for="firma4">
                Entre 2 - 4 abogados
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="firma5"
                id="firma5"
                v-model="form.tamFirma"
                value="2"
              />
              <label class="form-check-label" for="firma5">
                Entre 5-9 abogados
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                type="radio"
                v-model="form.tamFirma"
                name="firmaMas10"
                id="firmaMas10"
                value="3"
              />
              <label class="form-check-label" for="firmaMas10">
                Más de 10
              </label>
            </div>
          </div>
          <div class="form-group" v-if="!$v.$invalid">
            <vue-recaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="key"
              @expired="onExpired"
            >
            </vue-recaptcha>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12">
          <div class="requisitosDiv">
            <b>Requisitos para ser considerado</b>
            <hr />
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                Más de 5 años ejerciendo la abogacía electoral
              </li>
              <li class="list-group-item">
                Pasión por brindar un excelente servicio al cliente
              </li>
              <li class="list-group-item">
                Capacidad de explicar cuestiones legales complejas de manera sencilla
              </li>
              <li class="list-group-item">
                Experiencia con la tecnología y comodidad con la colaboración
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            class="btn"
            type="button"
            @click="solicitud"
            :disabled="!captcha"
          >
            Solicitar registro
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import { required, email } from "vuelidate/lib/validators";
import { auth, fr, analytics } from "../../../firebase";
import { mapState } from 'vuex';

export default {
  name: "RegistroFirma",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      form: {
        nombre: "",
        nombreEmpresa: "",
        email: "",
        sitio: "",
        tamFirma: null,
      },
      captcha: false,
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal:
        //"https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
    };
  },
  validations: {
    form: {
      nombre: { required },
      nombreEmpresa: { required },
      email: { required, email },
      sitio: { required },
      tamFirma: { required },
    },
  },
  methods: {
    async solicitud() {
      try {
        analytics.logEvent("registroMarketPlace");

        this.$swal({
          icon: "info",
          title: "Guardando comentario",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: true,
          didOpen: () => this.$swal.showLoading(),
        });
        const data = {
          ...this.form,
          fechaRegistro: Date.now(),
          usuario: auth.currentUser ? auth.currentUser.uid : 0,
          estatus: 0,
        };

        await fr.collection("registroMarketPlace").doc().set(data);
        this.$swal({
          icon: "success",
          title: "!Gracias por comunicarte!",
          text:"Nosotros nos pondremos en contacto, por el medio de preferencia seleccionado, en 1 o 2 días hábiles",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
        this.form.nombre = "";
        this.form.nombreEmpresa = "";
        this.form.email = "";
        this.form.sitio = "";
        this.form.tamFirma = null;
        this.captcha = false;
        this.$v.form.$reset();
        this.resetRecaptcha();

      } catch (error) {
        console.error(error);
        this.$swal({
          icon: "error",
          title: "Revisa tu conexión a internet e intenta de nuevo",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
        });
      }
    },
    onVerify: function (response) {
      if (response) {
        const url = process.env.NODE_ENV === "development" ? this.urlLocal : this.urlProd;
        fetch(url + "validateCaptcha/" + response)
          .then((res) => res.json())
          .then((res) => {
            console.info(res);
            this.captcha = true;
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
  },
  computed: {
    validateCaptcha() {
      return !this.$v.$invalid && this.captcha;
    },
    ...mapState('generals', ['key']),
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
.registroFirma {
  background-color: #f4f4f4;
  padding-top: 60px;
  padding-bottom: 60px;
}
.requisitosDiv {
  background: #9e9e9e33;
  padding: 20px;
  border-radius: 10px;
}
.list-group-item {
  background: none;
}

.form-group .error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


.title-text {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 26px;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 60px;
}
h2::after {
	content: "";
	width: 130px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}
</style>