<template>
    <div 
        class="modal fade"
        id="ModalPrime"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalPrime"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo"></h5>
          </div>
            <div class="container-fluid d-flex justify-content-center mx-0">
                <div class="row mt-2 mx-0">
                    <div class="col-sm-6 my-1">
                        <div class="card mx-auto" > <img src="assets/img/SuscripcionMes.png" class="card-img-top" width="100%">
                        
                            <div class="card-body pt-1 px-0">
                                <div class="d-flex flex-row justify-content-between my-2 px-3"> <small class="text-muted mt-1">PAGO MENSUAL DE</small>
                                    <h6 class="price-text">&dollar;250.00 <sub>MXN</sub></h6>
                                </div>
                                <hr class="mt-2 mx-3">
                                <div class="d-flex flex-row justify-content-between my-2 px-3"> 
                                    <p style="font-size: .9rem">
                                        Con una membresía mensual disfruta de acceso ilimitado a los contenidos y una consulta a la distribución de diputaciones por el principio de Representación Proporcional en Veracruz
                                    </p>
                                </div>
                                <hr class="mt-2-mx-3">
                                
                                <div class="mx-3 mt-3 mb-2"><button type="button" class="btn btn-danger btn-block" @click="pagarMes()"><small>SELECCIONAR MES</small></button>
                                </div> <small class="d-flex justify-content-center text-muted">*Aviso Legal</small>
                            </div>
                        </div>
                    </div> 

                    <div class="col-sm-6 my-1">
                        <div class="card mx-auto" > <img src="assets/img/SuscripcionSemana.png" class="card-img-top" width="100%">
                            <div class="card-body pt-1 px-0">
                                <div class="d-flex flex-row justify-content-between my-2 px-3"> <small class="text-muted mt-1">PAGO SEMANAL DE</small>
                                    <h6 class="price-text">&dollar;100.00 <sub>MXN</sub></h6>
                                </div>
                                <hr class="mt-2 mx-3">
                                <div class="d-flex flex-row justify-content-between my-2 px-3"> 
                                    <p style="font-size: .9rem">
                                        Con una membresía semanal disfruta de acceso ilimitado a contenidos como: reportes, artículos, infografías etc... No te pierdas de esta promoción por tiempo limitado
                                    </p>
                                </div>
                                <hr class="mt-2-mx-3">
                                
                                <div class="mx-3 mt-3 mb-2"><button type="button" class="btn btn-danger btn-block" @click="pagarSemana()"><small>SELECCIONAR SEMANA</small></button>
                                </div> <small class="d-flex justify-content-center text-muted">*Aviso Legal</small>
                            </div>
                        </div>
                    </div>

                    <div>
                        <span>.</span> 
                    </div>
                           
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import {auth , fr} from "../../../firebase";


export default {
    name: "ModalSuscripcionStart",
    data (){
        return{
            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },
    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd;
        },
    },
    methods: {
        async pagarMes(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 1,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalPrime").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        },

        async pagarSemana(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 0,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalPrime").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        }
        
    },
    
}
</script>

<style scoped>
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

