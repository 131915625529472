<template>
	<div class="row mt-2 responsive-size">
		<div class="col-md-12">
			<h2 class="title-text" style="font-size: 28px!important;">DESCUBRE NUESTROS <b style="color:#8830df">FORMATOS</b></h2>
			<div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="0">
			<!-- Carousel indicators -->
			<ol class="carousel-indicators">
				<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
				<li data-target="#myCarousel" data-slide-to="1"></li>
				<li data-target="#myCarousel" data-slide-to="2"></li>
			</ol>   
			<!-- Wrapper for carousel items -->
			<div class="carousel-inner">
				<div class="item carousel-item active">
          <!-- PRIMEROS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(0)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(1)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(2)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(3)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>


            </div>
          </div>
				</div>

				<div class="item carousel-item">
          <!-- SEGUNDOS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(4)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(5)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(6)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(7)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
				</div>

        <div class="item carousel-item">
          <!-- TERCEROS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(8)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(9)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(10)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(11)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(12)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(13)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
				</div>


			</div>
			<!-- Carousel controls -->
			<a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
				<i class="fa fa-angle-left"></i>
			</a>
			<a class="carousel-control-next" href="#myCarousel" data-slide="next">
				<i class="fa fa-angle-right"></i>
			</a>
		</div>
		</div>
	</div>

</template>

<script>
//mport { mapState } from "vuex";
//import { auth, fr, analytics } from "../../firebase";
//import VueRecaptcha from "vue-recaptcha";

export default {
  name: "FormatosMarket",
  metaInfo: {
    title: "Electoral Tech & legal | Marketplace ",
    description:"Acesorias legales y consultoria de temas político-electorales",
  },
  components: {
    //"vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
      captcha: false,
      formatos: [
        {
          id: 0,
          nombre: "Violencia política en razón de género. Juicio cuidadano(JDC-VPG)",
          descripcion: "¿Requieres de la emisión de medidas cautelares o de protección por parte del INE? Genera tu propia solicitud digital de una manera sencilla y rápida.",
          tipo: 1,
          grupo: 1,
        },
        {
          id: 1,
          nombre: "Denuncia en materia de Violencia Política contra las mujeres en razón de género. Procedimiento Especial Sancionador(PES-VPG)",
          descripcion: "¿Necesitas denunciar un acto de violencia política en razón de género? Olvídate de pagar honorarios y hazlo tú misma.",
          tipo: 2,
          grupo: 2,
        },
        {
          id: 2,
          nombre: "Derecho de petición (DP) ",
          descripcion: "¿Formulaste una solicitud de información y de expedición de copias\n" +
              "              certificadas sin que exista respuesta? Crea tu propia demanda para\n" +
              "              exigir la entrega completa, congruente y oportuna.",
          tipo: 3,
          grupo: 3,
        },
        {
          id: 3,
          nombre: "Apelación (RAP)",
          descripcion: "¿Te ha sido impuesta una sanción por infracciones electorales?\n" +
              "              ¿Garantizaron tu derecho de audiencia en el procedimiento? Entra\n" +
              "              aquí y en minutos podrás elaborar un recurso de apelación por ti\n" +
              "              mismo.",
          tipo: 4,
          grupo: 6,
        },
       {
          id: 4,
          nombre: "Autoridad Municipal Auxiliar (RAA)",
          descripcion: "¿Eres autoridad municipal auxiliar de elección popular ?\n"+ 
          "¿Sabías que el juicio electoral ciudadano protege tu derecho político-electoral en la vertiente de desempeñar el cargo y recibir el pago correspondiente?\n" + 
          "Elabora tu propia demanda de manera eficaz y eficiente.",
          tipo: 5,  
          grupo: 4,
        },
        {
          id: 5,
          nombre: "Juicio de revisión constitucional electoral (JRC)",
          descripcion: "¿Eres representante partidista y estás inconforme con la decisión del órgano jurisdiccional local?\n" + "Elabora tu propio escrito de revisión constitucional. O acércate a nuestro equipo de especialistas para apoyarte con la impugnación respectiva.",
          tipo: 6,  
          grupo: 5,
        },
        {
          id: 6,
          nombre: "Juicio Ciudadano (JDC-CPVF)",
          descripcion: "¿Requieres la reposición urgente de tu credencial para votar por robo o extravío? Aún estás a tiempo de proteger tu derecho al voto mediante un juicio ciudadano realizado de manera rápida y eficaz. Realiza tú mismo la petición en tiempo récord",
          tipo: 7,
          grupo: 7,
        },
        {
          id: 7,
          nombre: "Recurso de Incorformidad (RIN)",
          descripcion: "Una vez concluidos los cómputos distritales y municipales en caso de inconformidad tienes la oportunidad de presentar un medio de impugnación. Consulta como puedes hacerlo por ti mismo de manera rápida y eficaz",
          tipo: 8,
          grupo: 8,
        },
        {
          id: 8,
          nombre: "Juicio de Incorformidad (JIN)",
          descripcion: "Una vez concluidos los cómputos distritales a nivel federal en caso de inconformidad tienes la oportunidad de presentar un medio de impugnación. Consulta como puedes hacerlo por ti mismo de manera rápida y eficaz",
          tipo: 9,
          grupo: 8,
        },/*
        {
          id: 9,
          nombre: "Escrito de Tercer Interesado",
          descripcion: "Una vez concluidos los cómputos distritales a nivel federal en caso de inconformidad tienes la oportunidad de presentar un medio de impugnación. Consulta como puedes hacerlo por ti mismo de manera rápida y eficaz",
          tipo: 10,
          grupo: 10,
        },*/
        {
          id: 11,
          nombre: "Recurso de Revisión del PES (REP)",
          descripcion: "¿Necesitas promover un medio de impugnación contra la declaración de incompetencia de un órgano local del INE? Redacta por ti mismo el recurso correspondiente de manera práctica, sencilla y eficaz.",
          tipo: 11,
          grupo: 11,
        },
        {
          id: 12,
          nombre: "Acción Afirmativa Migrante (JDC)",
          descripcion: "¿Perteneces a un grupo vulnerado y/o pretendes solicitar la aplicación de una acción afirmativa? Elabora por ti mismo(a) la demanda para garantizar el derecho del grupo al que perteneces.",
          tipo: 12,
          grupo: 12,
        },
        {
          id: 13,
          nombre: "Recurso de Apelación (RAP)",
          descripcion: "¿Eres representante partidista ante el órgano administrativo electoral? Apóyate en nuestro formato digital para formular tu recurso de apelación contra la determinación sobre la omisión de reporte de gasto de pautas sin una valuación con bases objetivas.",
          tipo: 13,
          grupo: 6,
        },
      ]
    };
  },
  methods: {
    formatosPorId(id) {
      if (id == -1) {
        return this.formatos;
      }
      return this.formatos.filter(f => f.id === id);
    },
    verForm(tipo) {
      let name = "";
      switch (tipo) {
        case 1:
          
          name = "violencia";
          break;
        case 2:
          name = "denuciaViolencia";
          break;
        case 3:
          name = "solicitudInfo";
          break;
        case 4:
          name = "apelacion";
          break;
        case 5:
          name = "agentesMunicipales"
          break;
        case 6:
          name = "juicioDeRevision"
          break;
        case 7:
          name = "jdcCredencial"
          break;
        case 8:
          name = "recursoRIN"
          break;
        case 9:
          name = "juicioJIN"
          break;
        case 10:
          name = "tercerInteresado"
          break;
        case 20:
          name = "PromoJDC"
          break;
        case 21:
          name = "PromoRIN"
          break;
        
      }
      this.$router.push({name});
    },

  },

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');


.responsive-size {
    margin-left: 6rem;
    margin-right: 6rem;
  }

@media (max-width: 1200px) {
  .responsive-size {
    margin-left: 1px;
    margin-right: 1px;
  }
}

.title-text {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 10px;
	font-weight: 30;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 20px;
}
h2::after {
	content: "";
	width: 100px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}
.carousel {
	margin: 50px auto;
	padding: 0 70px;
  width:fit-content;  
  
}
.carousel .item {
	color: #747d89;
	min-height: 325px;
	text-align: center;
	overflow: hidden;
  
}
.carousel-control-prev, .carousel-control-next {
	height: 44px;
	width: 40px;
	background: #8830df;	
	margin: auto 0;
	border-radius: 4px;
	opacity: 0.8;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
	background: #8830df;
	opacity: 1;
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 36px;
	position: absolute;
	top: 50%;
	display: inline-block;
	margin: -19px 0 0 0;
	z-index: 5;
	left: 0;
	right: 0;
	color: #fff;
	text-shadow: none;
	font-weight: bold;
}
.carousel-control-prev i {
	margin-left: -2px;
}
.carousel-control-next i {
	margin-right: -4px;
}		
.carousel-indicators {
	bottom: -50px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 4px;
	border-radius: 50%;
	border: none;
}
.carousel-indicators li {	
	background: rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {	
	background: #2f0c71;
}

.promo-wrapper {
  cursor: pointer;
}

.homepage-3 .single-promo h3 {
  font-size: 28px;
}
.active{
  background: none;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #e3d6ff;
  border-bottom: solid 5px #2f0c71;
  color: black;
}
.nav-link{
  font-size: 18px;
}
.encabezados{
  overflow: auto;
}
.grad-hover::before{
  background: #2f0c71;
}
.parallax-style{
  background-color: #f4f4f4;
  padding-top: 40px;
  padding-bottom: 150px;
}


</style>