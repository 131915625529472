<template>
	<div class="row responsive-size parallax-style" >
		<div class="col-md-8">
      <h2 class="title-text" style="font-size: 28px!important;">ANALISIS Y <b style="color:#8830df">TEXTOS</b></h2>
			<div id="myCarousel2" class="carousel slide" data-ride="carousel" data-interval="0">
			<!-- Wrapper for carousel items -->
			<div class="carousel-inner">
				<div class="item carousel-item active">
          <!-- PRIMEROS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-3" v-for="f in librosPorId(0)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5" @click="verBookOrder(f.id)">
                  <div class="promo-wrapper">
                    <img :src="librosInfo[0].url" class="mb-1" alt="" width="100" height="180">
                    <h4 class="mb-1" style="font-size:.8em!important">{{ f.Titulo }}</h4>
                    <!-- <p>$ {{f.price}}</p> -->
                  </div>
                </div>
              </div>

              <!--
              <div class="col-12 col-md-6 col-lg-3" v-for="f in librosPorId(2)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verAnalisisOrder(0)">
                    <img src="assets/img/rp-diputa.png" class="mb-1" width="200" alt="">

                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in librosPorId(3)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verAnalisisOrder(1)">
                    <img src="assets/img/rp-ayuntamientos.png" class="mb-1" width="200" alt="">

                  </div>
                </div>
              </div>
              -->

              <div class="col-12 col-md-6 col-lg-3" v-for="f in librosPorId(4)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="solicitarAnalisis()">
                    <img src="assets/img/a-la-carta.png" class="mb-1" width="200" alt="">

                  </div>
                </div>
              </div>

            </div>
          </div>
				</div>

        <!-- SEGUNDOS 4 ITEMS ACTIVOS 
				<div class="item carousel-item">
          
          <div class="tab-pane fade show active">
            <div class="row">

              

            </div>
          </div>
				</div> 
        -->
			</div>
			<!-- Carousel controls -->
			<a class="carousel-control-prev" href="#myCarousel2" data-slide="prev">
				<i class="fa fa-angle-left"></i>
			</a>
			<a class="carousel-control-next" href="#myCarousel2" data-slide="next">
				<i class="fa fa-angle-right"></i>
			</a> 
		</div>
		</div>

    <!--
    <div class="col-md-4 text-center"> 
      <h2 class="title-text" style="font-size: 28px!important;">DESCARGA LA <b style="color:#8830df">APP</b></h2>
        <input type="image" width="420" height="210" class="img-centered" src="assets/img/app-ad.png" @click="appAd()">
    </div>
    -->
    
    <ModalBookOrder :idLibro="libroSelected"/>
    <ModalAnalisisOrder :idAnalisis="analisisSelected"/>
	</div>

</template>

<script>
//mport { mapState } from "vuex";
//import { auth, fr, analytics } from "../../firebase";
//import VueRecaptcha from "vue-recaptcha";
import ModalBookOrder from "../Modal/ModalBookOrder/ModalBookOrderIndex.vue";
import ModalAnalisisOrder from "../Modal/ModalBookOrder/ModalAnalisisOrder.vue";

export default {
  name: "LibrosIndexMarket",
  metaInfo: {
    title: "Electoral Tech & legal | Marketplace ",
    description:"Acesorias legales y consultoria de temas político-electorales",
  },
  components: {
    //"vue-recaptcha": VueRecaptcha,
      ModalBookOrder,
      ModalAnalisisOrder,
  },
  data() {
    return {
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
      captcha: false,

      librosInfo: [
        {id: 0,Titulo: "JUSTICIA ELECTORAL EN MÉXICO. AVANCES Y RETROCESOS A 20 AÑOS DE LA REFORMA ELECTORAL DE 1996",price: 240, url:"assets/img/book1-img.jpg"},
        {id: 1,Titulo: "Test Title 2",price: 390},
        {id: 2},
        {id: 3},
        {id: 4},
      ], 
      libroSelected: 0,
      analisisSelected: 0,



      
    };
  },
  mounted(){
    this.verBookOrder(0);
  },
  methods: {
    librosPorId(id) {
      if (id == -1) {
        return this.librosInfo;
      }
      return this.librosInfo.filter(f => f.id === id);
    },
    appAd(){
      window.open("https://play.google.com/store?hl=es_MX&gl=US", "_blank");
    },
    verBookOrder(id){
      this.libroSelected = id;
      window.$("#ModalBookOrder").modal("show");
    },
    verAnalisisOrder(id){
      this.analisisSelected = id;
      window.$("#ModalAnalisisOrder").modal("show");
    },
    solicitarAnalisis(){
      document.getElementById("serviciosConsulta12").scrollIntoView({
      behavior: "smooth"
      });
    }
  },

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.centered-img{
  margin-top: 42px;
  margin-bottom: 42px;

}

.responsive-size {
    padding-left: 6rem;
    padding-right: 6rem;
    margin-top: 10rem;
  }
  
.img-centered{
  margin-top: 3rem;
}


@media (max-width: 1200px) {
  .responsive-size {
    padding-left: 1px;
    padding-right: 1px;
    margin-top: 1rem;
  }
}

.title-text {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 26px;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 60px;
}
h2::after {
	content: "";
	width: 100px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}
.carousel {
	margin: 50px auto;
	padding: 0 70px;
}
.carousel .item {
	color: #747d89;
	min-height: 325px;
	text-align: center;
	overflow: hidden;
}
.carousel-control-prev, .carousel-control-next {
	height: 44px;
	width: 40px;
	background: #8830df;	
	margin: auto 0;
	border-radius: 4px;
	opacity: 0.8;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
	background: #8830df;
	opacity: 1;
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 36px;
	position: absolute;
	top: 50%;
	display: inline-block;
	margin: -19px 0 0 0;
	z-index: 5;
	left: 0;
	right: 0;
	color: #fff;
	text-shadow: none;
	font-weight: bold;
}
.carousel-control-prev i {
	margin-left: -2px;
}
.carousel-control-next i {
	margin-right: -4px;
}		
.carousel-indicators {
	bottom: -50px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 4px;
	border-radius: 50%;
	border: none;
}
.carousel-indicators li {	
	background: rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {	
	background: #2f0c71;
}

.promo-wrapper {
  cursor: pointer;
}

.homepage-3 .single-promo h3 {
  font-size: 28px;
}
.active{
  background: none;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #e3d6ff;
  border-bottom: solid 5px #2f0c71;
  color: black;
}
.nav-link{
  font-size: 18px;
}
.encabezados{
  overflow: auto;
}
.grad-hover::before{
  background: #2f0c71;
}
.parallax-style{
  background-color: #f4f4f4;
  padding-top: 110px;
  padding-bottom: 110px;
}

</style>
