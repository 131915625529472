<template>
    <div 
        class="modal fade"
        id="ModalAnalisisOrder"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalAnalisis"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title col-12 text-center textoTitulo"></h5>
          </div>
            <div class="container-fluid d-flex justify-content-center  mx-0">
                <div class="row mb-4">
                    <div class="col-sm-12 col-lg-4">
                        <div class="card mx-auto" > 
                            <img :src="infoLibros[idAnalisis].url" class="card-img-top">
                        </div>
                    </div> 
                    <div class="col-sm-12 col-lg-4"> 
                        <div class="card-body pt-1 px-0">
                            <div class="d-flex flex-row justify-content-between my-2 px-3"> 
                                <h3 style="text-align: justify;">{{infoLibros[idAnalisis].Titulo}}</h3>
                            </div>

                            <hr class="mt-2 mx-3">
                            <div class="d-flex flex-row justify-content-between my-2 px-3 texto-descriptivo"> 
                                <p align="justify" style="font-size: .9rem; ">
                                    <b>DESCRIPCION:</b>
                                    {{infoLibros[idAnalisis].abstract}}
                                 </p>
                            </div>
                            <hr class="mt-2-mx-3">
        
                            <span class="d-flex justify-content-center text-muted">Autor: <a href="">Electoral Tech Legal</a>  </span> 
                        </div>
                    </div>

                    <div class="col-sm-12 col-lg-4">
                        <div class="card-body pt-1 px-0">
                            <div class="d-flex flex-row justify-content-between my-2 px-3"> 
                                <h3 class="text-precio text-muted mt-1">PRECIO ELECTORALTECH</h3>
                                <h6 class="price-text mt-3">&dollar;{{ infoLibros[idAnalisis].price}} <sub>MXN</sub></h6>
                            </div>
                            <h6 class="text-precio text-muted mx-3">{{ infoLibros[idAnalisis].hint}}</h6>
                            <hr class="mt-2 mx-3">
                            <h6 class="ml-3 price-text mt-3">Información Adicional</h6>
                            <div class="px-3"> 
                                 <div class="row mt-5">
                                    <div class="col-2">
                                        <i class="fas fa-globe-americas icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b>Idioma:</b> {{infoLibros[idAnalisis].idioma}}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-2">
                                        <i class="fab fa-leanpub icons-info"></i> 
                                    </div>
                                    <div class="col-10 pt-3">
                                        <span class="info-text"> <b>Formato:</b> {{infoLibros[idAnalisis].formato}}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="infoLibros[idAnalisis].input">
                                    <div class="col-10 pt-3">
                                        <input v-model="infoLibros[idAnalisis].inputValue" class="form-control" type="text" :placeholder="infoLibros[idAnalisis].inputTitle">
                                    </div>
                                </div>
                                
                            </div>
                            <hr class="mt-4-mx-3">
                            
                            <div class="mx-3 mt-5 mb-2"><button type="button" class="btn btn-danger btn-block" @click="comprarLibro()">{{infoLibros[idAnalisis].accion}}<small></small></button>
                            </div> <small class="d-flex justify-content-center text-muted">*Aviso Legal</small>
                        </div>  
                    </div>

                    <div>
                        <span>.</span> 
                    </div>
                           
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import {auth , fr} from "../../../firebase";


export default {
    name: "ModalBookOrder",
    props: ["idAnalisis"],
    data (){
        return{
            infoLibros: [
                {
                    Titulo: "Conoce la proyección de lo que podría ser la próxima integración del Congreso de Veracruz",
                    abstract: "Conoce la proyección de lo que podría ser la próxima integración del Congreso de Veracruz, a partir de los resultados electorales del OPLEV, Ademas te mostramos los ajustes por partido podrían darse para garantizar la #paridad en la próxima integración del Congreso", 
                    sku: "1234567890",
                    editorial: "nombre de la editorial",
                    genero: "no",
                    idioma: "Español",
                    formato: "PDF",
                    price: 250,
                    url: "assets/img/rp-diputa.png",
                    hint: "*Este analisis esta incluido en la suscripcion ElectoralTech",
                    accion:"Solicitar",
                    input: false,
                    inputValue: "",
                    inputTitle: "",
                },

                {
                    Titulo: "Proyección de regidores por representación rroporcional en veracruz, solicita para el municipio de tu interés.",
                    abstract: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum arcu nec ornare mollis. Aliquam erat volutpat. Quisque a tincidunt turpis. Integer a sem tempus ligula rutrum vestibulum vitae vestibulum diam. Ut ac ante maximus, rhoncus mauris ut, egestas nisi. Praesent ultricies nunc eget quam venenatis, sed cursus risus semper. Nulla sagittis nisi tellus, eu gravida lectus sollicitudin eu. Fusce dictum dui vitae mauris lacinia lacinia. Morbi vehicula libero non ex faucibus, eget condimentum dui fringilla. Cras arcu sapien, ultricies id mauris quis, ultricies tempus quam. Nam viverra urna ut tellus lobortis ultrices. Nam sed pharetra sem, ac posuere ante. Aliquam vitae metus sed ipsum mattis mollis", 
                    sku: "90123456789",
                    editorial: "nombre de la editorial 2",
                    genero: "no",
                    idioma: "Español",
                    formato: "PDF",
                    price: 150,
                    url: "assets/img/rp-ayuntamientos.png",
                    hint: "",
                    accion:"Comprar",
                    input: true,
                    inputValue: "",
                    inputTitle: "Selecciona El Minicipio",
                },
            ],

            SelectedOption: "",
            captcha: false,
            urlProd:
                "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
            urlLocal:
                "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        }
    },

    computed: {
        url() {
        return process.env.NODE_ENV == "development"
            ? this.urlLocal
            : this.urlProd;
        },
    },
    methods: {
        comprarLibro(){
            console.log("ComprarLibro");

        },

        async pagarMes(){
            const user = auth.currentUser;
            const dt =  new Date().getTime()
            if (user !== null) {
                try{
                    const data = { 
                        uid: user.uid,
                        tipo: 1,
                        estado: 0,
                        voucherURL: "",
                        fechaSolicitud: dt,
                    };
                    console.log(data);
                    await fr.collection('suscripciones').doc(user.uid).set(data);
                    window.$("#ModalAnalisisOrder").modal("hide");
                    const result = await this.$swal({
                    icon: "success",
                    title: "Hecho",
                    text: "Tu solicitud de suscripcion se ha generado correctamente, revisa tu perfil para ver las opciones de pago.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showCancelButton: true,
                    confirmButtonText: 'aceptar',
                    });
                    if (result.isConfirmed) {
                    this.$router.push({ name: "perfil" });
                    } else if (result.isDenied) {
                    this.$router.push({ name: "inicio" });
                    console.log('no confirmo')
                    }
                    }catch (error) {
                        console.error(error);
                        this.$swal({
                        icon: "error",
                        title: "Revisa tu conexión a internet e intenta de nuevo",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: true,
                        });
                    }      
            }else{
                const result = await this.$swal({
                icon: "warning",
                title: "Error inesperado",
                text: "Ha ocurrido un error inesperado, revisa tu conexion a internet y intentalo nuevamente mas tarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                confirmButtonText: 'aceptar',
                });
                if (result.isConfirmed) {
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                this.$router.push({ name: "inicio" });
                console.log('no confirmo')
                }
            }
        }
        
    },
    
}
</script>

<style scoped>
.modal-body {
  display: block !important;
}

.info-text{
    margin-top: -20px;
}

.icons-info{
    width: 50px !important;
    height: 50px !important;
}

.texto-descriptivo{
    max-height: 600px !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

body {
    background: #F5F1EE;
    font-family: 'Roboto', sans-serif
}

.price-text{
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;
}

.card {
    width: 250px;
    border-radius: 10px
}

.card-img-top {
    height: 500px!important;
    width: 300px!important;
    max-width: 300px!important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

span.text-muted {
    font-size: 12px
}

small.text-muted {
    font-size: 8px
}

h5.mb-0 {
    font-size: 1rem
}

small.ghj {
    font-size: 9px
}

.mid {
    background: #ECEDF1
}

h6.ml-1 {
    font-size: 13px
}

small.key {
    text-decoration: underline;
    font-size: 9px;
    cursor: pointer
}

.btn-danger {
    color: #FFCBD2
}

.btn-danger:focus {
    box-shadow: none
}

small.justify-content-center {
    font-size: 9px;
    cursor: pointer;
    text-decoration: underline
}

@media screen and (max-width:600px) {
    .col-sm-4 {
        margin-bottom: 50px
    }
}

.textoTitulo{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: -webkit-linear-gradient(#cf1773,#9b32a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     color: tomato;

}

</style>

