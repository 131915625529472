<template>
  <section id="service" class="section service-area ">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <!-- <h2>¿Necesitas ayuda u orientación?</h2> 
          <h2>¡Contáctanos!</h2> -->
          <h2 id="serviciosConsulta12" class="title-text" style="font-size: 28px!important;">SERVICIOS DE  <b>ASESORÍA JURÍDICA</b></h2>
          <hr class="title-divider">
          <br>
          <h4>¿Necesitas ayuda u orientación?</h4>
          <h4>¡Contáctanos!</h4>
          <h6>
            Escríbenos un mensaje con tu problema o consulta legal y uno de nuestros expertos te atenderá.
          </h6>
        </div>
        <div class="col-12 mt-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-whatsapp"></i>
              </span>
            </div>
            <input
              type="number"
              v-model="numero"
              class="form-control"
            />
          </div>
          <small>Ingresa tu WhatsApp a 10 dígitos</small>
        </div>
        <div class="col-12 mt-3">
          <textarea
            v-model="consulta"
            cols="30"
            rows="10"
            class="form-control"
            placeholder="Cuéntanos que sucede, y con gusto te atenderemos..."
          ></textarea>
        </div>
        <div class="col-12 mt-3">
          <div class="d-flex justify-content-center">
            <div>
              <vue-recaptcha
                ref="recaptcha"
                @verify="onVerify"
                :sitekey="key"
                @expired="onExpired"
              >
              </vue-recaptcha>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 text-center mb-5">
          <button
            class="btn btn-primary"
            :disabled="isEnable"
            @click="guardarSolicitud"
          >
            <span class="text-white pr-3">
              <i class="fas fa-paper-plane"></i>
            </span>
            <span v-if="!logIn"
              >Inicia sesión o registrate para hacer una consulta</span
            >
            <span v-else>Realizar consulta</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { auth, fr, analytics } from "../../firebase";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "ServiceSeven",
  metaInfo: {
    title: "Electoral Tech & legal | Marketplace ",
    description:"Acesorias legales y consultoria de temas político-electorales",
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      consulta: "",
      numero: "",
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: //"http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
      "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      captcha: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["logIn", "usuarioLog"]),
    ...mapState("generals", ["key"]),
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
    isEnable() {
      if (this.logIn && this.captcha) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        const url = process.env.NODE_ENV === "development" ? this.urlLocal : this.urlProd;
        fetch(url + "validateCaptcha/" + response)
          .then((res) => res.json())
          .then((res) => {
            console.info(res);
            this.captcha = true;
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    async guardarSolicitud() {
      analytics.logEvent("nuevaSolicitud");
      try {
        this.sweetAlertLoading("Guardando solicitud");
        if (this.consulta != "") {
          await fr
            .collection("consultas")
            .doc()
            .set({
              fechaRegistro: Date.now(),
              consulta: this.consulta,
              usuario: {
                nombre: this.usuarioLog.nombre,
                apellidos: this.usuarioLog.apellidos,
                correo: this.usuarioLog.correo,
              },
              numero: this.numero,
              usuarioRegistro: auth.currentUser.uid,
              estatus: 0,
            });
            await fr
            .collection("notificaciones-app")
            .doc()
            .set({
              fechaRegistro: Date.now(),
              consulta: this.consulta,
              usuarioRegistro: auth.currentUser.uid,
              notification_label: "Se realizó una nueva solicitud",
              estatus: 0,
            });
          this.resetRecaptcha();
          this.sweetAlert(
            "success",
            "Gracias por comunicarte ",
            "Un experto te contactará al telefono enviado o al correo registrado en la cuenta. Puedes ver tus solicitudes en tu perfil, en la parte superior."
          );
          this.consulta = "";
          this.numero = "";
        }
      } catch (error) {
        this.sweetAlert(
          "error",
          "Error",
          "Revisa tu conexión a internet e intenta de nuevo"
        );
        console.error(error);
      }
    },
    sweetAlert(tipo, titulo, texto) {
      this.$swal({
        icon: tipo,
        title: titulo,
        text: texto,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    },
    sweetAlertLoading(titulo) {
      this.$swal({
        icon: "info",
        title: titulo,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: true,
        showCancelButton: true,
        didOpen: () => this.$swal.showLoading(),
      });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
.title-text {
  margin-top: 100px;
  padding-top: 40px;
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 26px;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 60px;
}
h2::after {
	content: "";
	width: 100px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}
.form-control{
  box-shadow: 1px 2px 2px 2px #8830df;
}
</style>