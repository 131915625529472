<template>
  <div class="blogMaketContainer">
    <h2 class="title-text" style="font-size: 28px!important;">SUSCRÍBETE Y OBTÉN ACCESO A  <b>CONTENIDO EXCLUSIVO</b></h2>
    <div class="row margin-reduced">
      <div class="col-md-12 col-lg-6 img-centered">
        <input type="image" class="responsive" src="assets/img/sus-ad.png" @click="alertPrime()">
      </div>
      <div class="col-lg-12 col-xl-6">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" >
              <img src="assets/img/calculo-rp.png" width="900" height="400" alt="" @click="blogAd()">
            </div>
            <div class="carousel-item">
              <img src="assets/img/sx-ad.png" width="900" height="400" alt="" @click="blogAd()">
            </div>
            <div class="carousel-item">
              <img src="assets/img/sx-ad.png" width="900" height="400" alt="" @click="blogAd()">
            </div>
            <div class="carousel-item">
              <img src="assets/img/TEV-AD.png" width="900" height="400" alt="" @click="blogAd()">
            </div>
            <div class="carousel-item">
              <img src="assets/img/ss-ad.png" width="900" height="400" alt="" @click="blogAd()">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

      </div>

    </div>
    <!-- Carousel indicators 
    <div class="row cards-margin">
      <div class="col-md-12">
        <div id="CarouselBlog" class="carousel slide" data-ride="carousel" data-interval="0">
        
        <ol class="carousel-indicators">
          <li data-target="#CarouselBlog" data-slide-to="0" class="active"></li>
          <li data-target="#CarouselBlog" data-slide-to="1"></li>
          <li data-target="#CarouselBlog" data-slide-to="2"></li>
        </ol>   
        <div class="carousel-inner">
          <div class="item carousel-item active">
            <div class="tab-pane fade show active">
              <div class="row">

                <div class="col-12 col-md-6 col-lg-3" v-for="n in 4" :key="n">
                  <div class="single-promo grad-hover text-center p-5" @click="blogAd()">
                    <img :src="imgsReportes[n].url" width="900" height="400" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="item carousel-item">
            <div class="tab-pane fade show active">
              <div class="row">

                <div class="col-12 col-md-6 col-lg-3" v-for="n in 4" :key="n">
                  <div class="single-promo grad-hover text-center p-5" @click="blogAd()">
                    <img :src="imgsReportes[n+3].url" width="900" height="400" alt="">
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="item carousel-item">
            <div class="tab-pane fade show active">
              <div class="row">

                <div class="col-12 col-md-6 col-lg-3" v-for="n in 4" :key="n">
                  <div class="single-promo grad-hover text-center p-5" @click="blogAd()">
                    <img :src="imgsReportes[n+7].url" width="900" height="400" alt="">
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
        <a class="carousel-control-prev" href="#CarouselBlog" data-slide="prev">
          <i class="fa fa-angle-left"></i>
        </a>
        <a class="carousel-control-next" href="#CarouselBlog" data-slide="next">
          <i class="fa fa-angle-right"></i>
        </a>
      </div>     
    </div>
    -->

    <ModalRegistro />
    <ModalSuscripcionStart />
  </div>
</template>

<script>
//mport { mapState } from "vuex";
//import { auth, fr, analytics } from "../../firebase";
//import VueRecaptcha from "vue-recaptcha";
import ModalRegistro from '../Modal/ModalSearch/ModalRegistro'
import ModalSuscripcionStart from "../Modal/ModalPrime/ModalSuscripcionStart.vue"
import {auth} from "../../firebase";
import { fr } from "../../firebase";
import { mapMutations } from "vuex";

export default {
  name: "BlogMarket",
  metaInfo: {
    title: "Electoral Tech & legal | Marketplace ",
    description:"Acesorias legales y consultoria de temas político-electorales",
  },
  components: {
    //"vue-recaptcha": VueRecaptcha,
    ModalRegistro,
    ModalSuscripcionStart,
  },
  data() {
    return {
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
      captcha: false,
      primeIndicator: false,
      imgsReportes: [
        {url: "assets/img/sesiones-salas/ss-04-08.jpg" },
        {url: "assets/img/sesiones-salas/sx-02-06.jpg" },
        {url: "assets/img/sesiones-salas/tev-01-06.jpg" },
        {url: "assets/img/sesiones-salas/sx-25-05.jpg" },

        {url: "assets/img/sesiones-salas/ss-05-06.jpg" },
        {url: "assets/img/sesiones-salas/tev-03-06.jpg" },
        {url: "assets/img/sesiones-salas/sx-02-07.jpg" },
        {url: "assets/img/sesiones-salas/tev-30-06.jpg" },

        {url: "assets/img/sesiones-salas/ss-07-07.jpg" },
        {url: "assets/img/sesiones-salas/ss-23-06.jpg" },
        {url: "assets/img/sesiones-salas/sx-04-06.jpg" },
        {url: "assets/img/sesiones-salas/tev-05-06.jpg" },

      ],
      
    };
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
    }),

    blogAd(){
      this.$router.push("/blog"); 
    },

    async actualizarUsuario(){
      if ( auth.currentUser != null ) {
        const actualizaInfo = await fr
        .collection("usuarios")
        .doc(auth.currentUser.uid)
        .get()
        this.setUsuario(actualizaInfo.data())
        window.location.reload();
      }
    },

    async alertPrime() {
      // window.$("#ModalCaptcha").modal("show");}
      if ( auth.currentUser != null ) {
          this.suscribeModal();
      } else {
          const result = await this.$swal({
          icon: "info",
          title: "Registrate o Inicia sesión!",
          text: "¡Antes de suscribirte a ElectoralTech crea tu cuenta, es Gratis!",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Crear cuenta ahora',
          });
          if (result.isConfirmed) {
          window.$("#registro").modal('show');
          } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          console.log('no confirmo')
          }
      }
    },

    async suscribeModal(){
      if (this.primeIndicator == false){
        window.$("#ModalPrime").modal("show");
      }else{
        const result = await this.$swal({
          icon: "info",
          title: "¡Ya Eres Un Miembro Suscrito!",
          text: "Puedes disfrutar de todo el contenido de exclusivo de ElectoralTech consultando el blog",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Ir Al Blog',
          });
          if (result.isConfirmed) {
            this.$router.push("/blog"); 
          } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          }
      }
      
    },

    obtenerIdicadorPrime(){
      if(sessionStorage.getItem("usuario")){
        const userData = JSON.parse(sessionStorage.getItem("usuario"));
        if(userData.prime==1){
          this.primeIndicator = true;
        }
        console.log("TU IDICADOR PRIME: "+ this.primeIndicator);
      }
    },

  },
  mounted() {
    this.obtenerIdicadorPrime();
    this.actualizarUsuario();
  },

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.blogMaketContainer{
  margin-top: 100px;
}

.margin-reduced{
  margin-bottom: -50px;
}

.title-text {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 26px;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 60px;
}
h2::after {
	content: "";
	width: 130px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}

.responsive {
  width: 100%;
  height: auto;
  max-height: 450px;
}

.img-centered{
  padding-left: 65px!important;
  padding-right: 1px!important
  
}

.cards-margin{
  margin-left: 200px!important;
  margin-right: 200px!important;
}

@media (max-width: 1200px) {
  .img-centered{
    padding-left: auto;
    padding-right: auto;  
  }
  .cards-margin{
  margin-left: 40px!important;
  margin-right: 40px!important
}
}

.responsive-size {
    margin-left: 6rem;
    margin-right: 6rem;
  }

@media (max-width: 1200px) {
  .responsive-size {
    margin-left: 1px;
    margin-right: 1px;
  }
}

.title-text {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 26px;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 60px;
}
h2::after {
	content: "";
	width: 100px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}
.carousel {
	margin: 50px auto;
	padding: 0 70px;
}
.carousel .item {
	color: #747d89;
	min-height: 325px;
	text-align: center;
	overflow: hidden;
}
.carousel-control-prev, .carousel-control-next {
	height: 44px;
	width: 40px;
	background: #8830df;	
	margin: auto 0;
	border-radius: 4px;
	opacity: 0.8;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
	background: #8830df;
	opacity: 1;
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 36px;
	position: absolute;
	top: 50%;
	display: inline-block;
	margin: -19px 0 0 0;
	z-index: 5;
	left: 0;
	right: 0;
	color: #fff;
	text-shadow: none;
	font-weight: bold;
}
.carousel-control-prev i {
	margin-left: -2px;
}
.carousel-control-next i {
	margin-right: -4px;
}		
.carousel-indicators {
	bottom: -50px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 4px;
	border-radius: 50%;
	border: none;
}
.carousel-indicators li {	
	background: rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {	
	background: #2f0c71;
}

.promo-wrapper {
  cursor: pointer;
}

.homepage-3 .single-promo h3 {
  font-size: 28px;
}
.active{
  background: none;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #e3d6ff;
  border-bottom: solid 5px #2f0c71;
  color: black;
}
.nav-link{
  font-size: 18px;
}
.encabezados{
  overflow: auto;
}
.grad-hover::before{
  background: #f7a8f767;
}
.parallax-style{
  background-color: #f4f4f4;
  padding-top: 40px;
  padding-bottom: 150px;
}


</style>